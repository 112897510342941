@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

html {
  scroll-behavior: smooth;
}

* {
  font-family: 'Montserrat', sans-serif !important;
}

.ant-input-affix-wrapper {
  border: 1px solid lightgrey !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-thumb {
  background: #242424;
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 0px solid #f0f0f0 !important;
  content: '';
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a,
.ant-menu-dark .ant-menu-item>span>a {
  color: black !important;
}

.ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: rgb(255, 190, 15) !important;
  font-size: 29px !important;
  line-height: unset;
  list-style: none;
  outline: none;
}

.ant-input-affix-wrapper::before {
  display: none;
}

.ant-card-extra {
  float: none !important;
  /* margin-left: auto; */
  margin: auto !important;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}

.ant-spin-container::after {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100vw !important;
  height: 100vh !important;
  background: #ffffff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex !important;
  margin-left: auto;
  margin-right: auto;
}

.slick-initialized .slick-slide {
  display: block;
  margin-right: 4em !important;
  padding: 1em !important;
  min-width: 14em !important;

}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  padding-top: 1em !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-responsive-modal-closeButton {
  background-color: rgba(255, 255, 255, 0.704) !important;
  border-radius: 4em !important;
  // top: 10px !important;
  // right: 15px !important;
  z-index: 1 !important;
}

.ant-layout-sider-children {
  box-shadow: 0px 0px 20px 8px rgba(155, 155, 155, 0.1);
  height: 100vh !important;
  margin-top: -0.1px;
  padding-top: 0.1px;
}

.react-responsive-modal-container {
  height: auto !important;
  outline: 0;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  text-align: center;
}

.react-responsive-modal-modal {
  text-align: center !important;
  border-radius: 1em;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  max-width: 100% !important;
  width: 25em !important;
  margin-top: 20vh !important;
  padding: 2em !important;
  background: #262626 !important;
  box-shadow: none !important;

}


.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: white !important;
}

.ant-menu-dark .ant-menu-item-selected>a,
.ant-menu-dark .ant-menu-item-selected>span>a,
.ant-menu-dark .ant-menu-item-selected>a:hover,
.ant-menu-dark .ant-menu-item-selected>span>a:hover {
  color: #fff !important;
}

.at-toolbar {
  display: none !important;
}

.ant-tooltip-inner {
  color: white !important;
}

.inputs-group {
  svg {
    width: 25px;
    height: 25px;
  }

  border-radius: 0.5rem;
  box-shadow: 0px 0px 20px 8px rgba(0, 180, 188, 0.17);
  padding: 20px 50px;

  .ant-form-item {
    margin-bottom: 0;
    border-bottom: 1px solid #bdb2b2;

    &:last-child {
      margin-bottom: 2rem;
      border-bottom: none;
    }

    .ant-form-explain {
      padding-left: 1rem;
    }

    .ant-form-item-label {
      color: #00b4bc;
    }
  }

  .ant-legacy-form-vertical .ant-legacy-form-item {
    padding-bottom: 80px !important;
  }

  span.ant-input-affix-wrapper {
    box-shadow: none;
  }

  .ant-legacy-form-vertical .ant-legacy-form-item textarea.ant-input,
  input.ant-input {
    box-shadow: none;
    border: none;
    border-radius: 0;

    &:first-of-type {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    &:last-of-type {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}

a.ql-action:after {
  border-right: 1px solid #ccc;
  content: 'Save' !important;
  margin-left: 16px;
  padding-right: 8px;
}

a.ql-remove:before {
  content: 'Remove' !important;
  margin-left: 8px;
}

.ql-tooltip:before {
  content: 'Link:' !important;
}

.ql-snow .ql-tooltip {
  margin: auto !important;
  left: 0 !important;
  width: 28em !important;
  right: 0 !important;
  position: fixed !important;
  margin-top: 2em;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;
}

.ant-card-cover img {
  // background: lightgrey !important;
  border-radius: 2px 2px 0 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: transparent !important;
  /* border: 1px solid #e9e9e9; */
  color: white !important;
  height: 45px !important;
  padding: 5px 11px !important;
  border: 1px solid white !important;
  border-radius: 0px !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-input-number-disabled .ant-input-number-input {
  cursor: text !important;
  font-weight: 500 !important;
}

body {
  overflow-x: hidden !important;
}

.editor-wrapper .quill .ql-container {
  border: 0px solid #ebedf0 !important;
  min-height: 380px;
  max-height: 1000em !important;
  overflow: auto;
}

.react-player__shadow {
  display: none !important;

}

.ant-card-body {
  padding: 15px !important;
}

// .react-player__preview {
//   background-size: contain !important;
//   background-repeat: no-repeat;
//     background-position:center;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//   border-radius: 1em !important;
// }

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: white !important;
}

.ant-list-item-meta-description {
  color: white;
  font-size: 14px;
  line-height: 15px !important;
}

.ant-spin-dot-spin {
  position: fixed !important;
}

// .react-responsive-modal-modal {

//   margin: 1.2rem !important;
//   padding: 0rem !important;

// }